import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  let navigate=useNavigate();
  return (
    <div className="container">
      <div className="backContent" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={<Button className="backBtn" on onClick={()=> navigate(-1)}>Back</Button>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
