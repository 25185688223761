import React, { useState } from "react";
import { Form, Input, Button, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import Logopane from "../../components/Sidebar/Logopane";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { supabase } from "../../../supabaseClient";
import "./OtpSignIn.css";
import axios from "axios";

const OtpSignin = () => {
  let navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");
  const { Text } = Typography;
  const onSubmit = async () => {
    await axios
      .get(`https://us-central1-ryki-client.cloudfunctions.net/reiki-cloud-functions?otp=${otp}&secret=${supabase.auth.session().access_token}`)
      .then((response) => {
        if (response.data === true) {
          setError(false);
          navigate("/dashboard");
        }
        else {
          setError(true);
        }
      }
      );
  };
  return (
    <div className="container">
      <div style={{ width: '50%', height: '100%', background: '#0C1A28' }}>
        <Logopane />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
          background: "#0F1E2E",
          height: "100%"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Text style={{ color: "white", fontSize: '2.29vw', fontFamily: "DisRegular" }}>Welcome Back</Text>
        </div>
        <div style={{ marginTop: "70px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px" }}>
          <Text style={{ color: "white", fontSize: '1.25vw', fontFamily: "Regular", textAlign: "center" }}>Please enter the OTP you see on your Google Authenticator App</Text>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Form
            style={{ padding: "80px", paddingTop: "70px" }}
            layout="vertical"
            name="login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onSubmit}
          >
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Please enter your OTP!",
                },
              ]}
            >
              <Input
                bordered={false}
                className="input-div"
                placeholder="OTP"
                onChange={(e) => setOTP(e.target.value)}
              />
            </Form.Item>
            {
              error ?
                (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <ExclamationCircleOutlined style={{ color: "red", fontSize: "1.25vw" }} />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <Text style={{ color: "white", fontFamily: "Regular" }}>The OTP you entered is Incorrect</Text>
                    </div>
                  </div>
                ) :
                (<div></div>
                )
            }
            <Form.Item style={{ display: "flex", justifyContent: "center", marginTop: "8.76vh" }}>
              <Space>
                <Button
                  className="signupBtn"
                  htmlType="submit"
                >
                  Log In
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default OtpSignin;
