import React, { useEffect, useState } from "react";
import { Typography, Space } from "antd";
import Header from "../../components/Header/Header";
import Bit from "../../../Assets/BTCS.svg";
import Eth from "../../../Assets/ETHS.svg";
import Usdc from "../../../Assets/USDS.svg";
import Bnb from "../../../Assets/USDTS.svg";
import USD from "../../../Assets/USD.svg";
import USDD from "../../../Assets/USDD.png";
import ETHD from "../../../Assets/ETHD.png";
import USDCD from "../../../Assets/USDCD.png";
import BTCD from "../../../Assets/BTCD.png";
import USDTD from "../../../Assets/USDTD.png";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../supabaseClient";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import "./Dashboard.css";
var bigCardName = "";
var allClientPoolCurrecy = [];
const colors = [
  "#38DEB6",
  "#C7A2C8",
  "#0093C2",
  "#F6A152",
  "#8F76FF",
  "#9FA5D3",
  "#29AB75",
  "#52CFF6",
  "#FFFA76",
  "#4C5CEC",
  "#C24600",
  "#8431D8",
  "#BFE9E1",
];
const Dashboard = () => {
  allClientPoolCurrecy = [];
  const [username, setUsername] = useState("");
  const [assets, setuserassets] = useState([]);
  const [isShown, setIsShown] = useState(true);
  const [investmentDetails, setInvestmentDetails] = useState([]);
  const [investor, setinvestor] = useState(true);
  const [clientpool, setClientPool] = useState([]);
  const [geteth, seteth] = useState("");
  const [getfiat, setfiat] = useState("");
  const { Text } = Typography;
  let navigate = useNavigate();
  let user = supabase.auth.user();
  useEffect(() => {
    supabase
      .from("profile")
      .select("*")
      .eq("user_id", user.id)
      .then(({ data }) => {
        if (user) {
          axios
            .post("/api/fireblock", {
              fireBlock_id: data[0].fireBlockID,
            })
            .then((res) => {
              supabase
                .from("profile")
                .select("*")
                .eq("user_id", user.id)
                .then(({ data }) => {
                  setUsername(data[0].name);
                  setuserassets(res.data[0].assets);
                  if (data[0].fiat_amount !== null) {
                    setfiat(data[0].fiat_amount);
                    seteth(data[0].eth_amount);
                  }
                  setInvestmentDetails([]);
                  supabase
                    .from("clientpool")
                    .select("*")
                    .eq("client_id", data[0].id)
                    .then(({ data }) => {
                      setClientPool(data);
                      data.forEach((ele) => {
                        supabase
                          .from("investmentDetails")
                          .select("*")
                          .eq("id", ele.investment_id)
                          .then(({ data }) => {
                            setInvestmentDetails((investmentDetails) => [
                              ...investmentDetails,
                              data[0],
                            ]);
                          });
                      });
                      if (data[0] !== undefined) {
                        setinvestor(true);
                      } else {
                        setinvestor(false);
                      }
                    });
                  setIsShown(false);
                });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
  }, []);
  let mymap = new Map();
  var unique = investmentDetails.filter((el) => {
    const val = mymap.get(el.currency);
    if (val) {
      if (el.currency === val) {
        mymap.delete(el.currency);
        mymap.set(el.currency, el.id);
        return true;
      } else {
        return false;
      }
    }
    mymap.set(el.currency, el.id);
    return true;
  });
  var filterUSD = clientpool.filter((ele) => {
    if (ele.currency === "USD") return ele;
  });
  var filterETH = clientpool.filter((ele) => {
    if (ele.currency === "ETH") return ele;
  });
  if (!investor)
    return (
      <>
        {isShown ? (
          <Loader />
        ) : (
          <>
            <Header />
            <div
              className="dashContainer"
            >
              <div style={{marginLeft: "8.5%",width:"82.5%"}}>
                <div>
                  <div>
                    <Text className="user-text">Hi, {username}</Text>
                  </div>
                  <div>
                    <Text className="user-assets">
                      View your{" "}
                      <Text
                        style={{
                          fontFamily: "Bold",
                          fontSize: "1.53vw",
                          color: "white",
                        }}
                      >
                        Assets
                      </Text>{" "}
                      Below
                    </Text>
                  </div>
                </div>
                <div className="cardHolder">
                  <Space
                     direction="vertical"
                     className="currencyCard"
                  >
                    <div style={{ marginBottom: "3vh" }}>
                      {filterUSD[0] === undefined ? (
                        <div
                          style={{ width: "100%", position: "relative" }}
                          onClick={() =>
                            navigate("/Tab", { state: { id: "USD" } })
                          }
                        >
                          <img
                            style={{ objectFit: "contain", width: "100%" }}
                            alt="USD"
                            src={USDD}
                          />
                          <div
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "10%",
                            }}
                          >
                            <img
                              src={USD}
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                          <Space
                            direction="vertical"
                            size={0}
                            className="valueD"
                          >
                            <div>
                              <Text className="textId">USD</Text>
                            </div>
                            <div>
                              <Text className="valueId">
                                ${Number(getfiat).toFixed(2)}
                              </Text>
                            </div>
                            <div
                              style={{
                                height: "5px",
                                width: "100%",
                                marginTop:"10px",
                                backgroundColor: `${colors[0]}`,
                              }}
                            ></div>
                          </Space>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      {filterETH[0] === undefined ? (
                           <div
                           style={{ width: "100%", position: "relative" }}
                           onClick={() =>
                             navigate("/Tab", { state: { id: "ETH" } })
                           }
                         >
                           <img
                             style={{ objectFit: "contain", width: "100%" }}
                             alt="USD"
                             src={ETHD}
                           />
                           <div
                             style={{
                               position: "absolute",
                               right: "5%",
                               top: "10%",
                             }}
                           >
                             <img
                               src={Eth}
                               style={{ width: "50px", height: "50px" }}
                             />
                           </div>
                           <Space
                             direction="vertical"
                             size={0}
                             className="valueD"
                           >
                             <div>
                               <Text className="textId">ETH</Text>
                             </div>
                             <div>
                               <Text className="valueId">
                               {Number(geteth).toFixed(4)}
                               </Text>
                             </div>
                             <div
                               style={{
                                 height: "5px",
                                 width: "100%",
                                 marginTop:"10px",
                                 backgroundColor: `${colors[0]}`,
                               }}
                             ></div>
                           </Space>
                         </div>
                      ) : (
                        <></>
                      )}
                      {assets.map((e, i) => {
                        if (
                          (e.id === "USDT_ERC20" ||
                            e.id === "BTC" ||
                            e.id === "USDC") &&
                          e.available !== "0"
                        )
                          return (
                            <div
                            style={{ width: "100%", position: "relative" }}
                            onClick={() =>
                              navigate("/Tab", { state: { id: e.id } })
                            }
                          >
                            <img
                              style={{ objectFit: "contain", width: "100%" }}
                              alt={e.id}
                              src={ e.id === "BTC"
                              ?BTCD
                              :e.id === "USDC"
                              ?USDCD
                              :USDTD}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "10%",
                              }}
                            >
                              <img
                                src={
                                e.id === "BTC"
                                ? Bit
                                : e.id === "USDC"
                                ? Usdc
                                : Bnb}
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                            <Space
                              direction="vertical"
                              size={0}
                              className="valueD"
                            >
                              <div>
                                <Text className="textId">{e.id === "USDT_ERC20" ? "USDT" : e.id}</Text>
                              </div>
                              <div>
                                <Text className="valueId">
                                {Number(e.available).toFixed(e.id==='BTC'?8:4)}
                                </Text>
                              </div>
                              <div
                                style={{
                                  height: "5px",
                                  width: "80%",
                                  marginTop:"10px",
                                  backgroundColor: `${colors[0]}`,
                                }}
                              ></div>
                            </Space>
                          </div>
                          );
                      })}
                    </div>
                  </Space>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  else
    return (
      <>
        {isShown ? (
          <Loader />
        ) : (
          <>
            <Header />
            <div
            className="dashContainer"
            >
              <div style={{marginLeft: "8.5%",width:"82.5%"}}>
                <div>
                  <div>
                    <Text className="user-text">Hi,{username}</Text>
                  </div>
                  <div>
                    <Text className="user-assets">
                      View your{" "}
                      <Text
                        style={{
                          fontFamily: "Bold",
                          fontSize: "1.53vw",
                          color: "white",
                        }}
                      >
                        Assets
                      </Text>{" "}
                      Below
                    </Text>
                  </div>
                </div>
                <div className="cardHolder">
                  <Space
                    direction="vertical"
                   className="currencyCard"
                  >
                    <div>
                      {filterUSD[0] === undefined ? (
                         <div
                         style={{ width: "100%", position: "relative" }}
                         onClick={() =>
                           navigate("/Tab", { state: { id: "USD" } })
                         }
                       >
                         <img
                           style={{ objectFit: "contain", width: "100%" }}
                           alt="USD"
                           src={USDD}
                         />
                         <div
                           style={{
                             position: "absolute",
                             right: "5%",
                             top: "10%",
                           }}
                         >
                           <img
                             src={USD}
                             style={{ width: "50px", height: "50px" }}
                           />
                         </div>
                         <Space
                           direction="vertical"
                           size={0}
                           className="valueD"
                         >
                           <div>
                             <Text className="textId">USD</Text>
                           </div>
                           <div>
                             <Text className="valueId">
                               ${Number(getfiat).toFixed(2)}
                             </Text>
                           </div>
                           <div
                             style={{
                               height: "5px",
                               width: "100%",
                               marginTop:"10px",
                               backgroundColor: `${colors[0]}`,
                             }}
                           ></div>
                         </Space>
                       </div>
                      ) : (
                        <></>
                      )}
                      {clientpool.map((e, i) => {
                        var sum = 0;
                        var currDaysDiff = 0;
                        var Unallocated = 0;
                        var duplicateCount = 0;
                        assets.forEach((ele) => {
                          if (e.currency === ele.id) Unallocated = ele.total;
                          if (e.currency === "USD") Unallocated = getfiat;
                        });

                        clientpool.forEach((ele) => {
                          if (ele.currency === e.currency) {
                            investmentDetails.forEach((ele1) => {
                              if (ele1.id === Number(ele.investment_id)) {
                                var effectiveDate = new Date(
                                  ele1.effectiveDate
                                );
                                var returnDate = new Date(ele1.expiryDate);
                                var date = new Date();
                                var currDate = new Date(
                                  date.getFullYear() +
                                    "-" +
                                    (date.getMonth() + 1) +
                                    "-" +
                                    date.getDate()
                                );
                                const diffTime = Math.abs(
                                  returnDate - effectiveDate
                                );
                                const diffCurrTime = Math.abs(
                                  currDate - effectiveDate
                                );

                                const diffDays =
                                  ele1.structure === "OT"
                                    ? Math.ceil(
                                        diffCurrTime / (1000 * 60 * 60 * 24)
                                      )
                                    : Math.ceil(
                                        diffTime / (1000 * 60 * 60 * 24)
                                      );

                                currDaysDiff = Math.ceil(
                                  diffCurrTime / (1000 * 60 * 60 * 24)
                                );
                                if (
                                  diffDays > currDaysDiff ||
                                  ele1.structure === "OT"
                                ) {
                                  currDaysDiff = Math.ceil(
                                    diffCurrTime / (1000 * 60 * 60 * 24)
                                  );
                                } else {
                                  currDaysDiff = diffDays;
                                }
                              }
                            });
                            sum =
                              sum +
                              (Number(ele.quantity) +
                                Number(ele.daily_interest) * currDaysDiff +
                                (duplicateCount === 0
                                  ? Number(Unallocated)
                                  : 0));
                            duplicateCount++;
                          }
                        });
                        var filterClietpool = clientpool.filter((ele) => {
                          if (ele.currency === e.currency) return ele;
                        });
                        if (e.currency === "USD" && i===1) {
                          bigCardName = e.currency;
                          allClientPoolCurrecy.push(e.currency);
                          return (
                            <div
                              style={{ width: "100%", position: "relative" }}
                              onClick={() =>
                                navigate("/Tab", { state: { id: "USD" } })
                              }
                            >
                              <img
                                style={{ objectFit: "contain", width: "100%" }}
                                alt="USD"
                                src={USDD}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  right: "5%",
                                  top: "10%",
                                }}
                              >
                                <img
                                  src={USD}
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <Space
                                direction="vertical"
                                size={0}
                                className="valueD"
                              >
                                <div>
                                  <Text className="textId">
                                    {e.currency === "USDT_ERC20"
                                      ? "USDT"
                                      : e.currency}
                                  </Text>
                                </div>
                                <div>
                                  <Text className="valueId">
                                    {Number(sum).toFixed(4)}
                                  </Text>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "5px",
                                      width: `${(Unallocated / sum) * 100}%`,
                                      backgroundColor: "#38DEB6",
                                    }}
                                  >
                                    {" "}
                                  </div>
                                  {filterClietpool.map((e1, i) => {
                                    if (e1.currency === e.currency) {
                                      return (
                                        <div
                                          key={i}
                                          style={{
                                            display: "flex",
                                            height: "5px",
                                            width: `${
                                              (e1.quantity / sum) * 100
                                            }%`,
                                            backgroundColor: `${colors[i + 1]}`,
                                          }}
                                        ></div>
                                      );
                                    }
                                  })}
                                </div>
                              </Space>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div>
                      {unique.map((e, i) => {
                        var sum = 0;
                        var currDaysDiff = 0;
                        var Unallocated = 0;
                        var duplicateCount = 0;
                        clientpool.forEach((ele) => {
                          if (ele.currency === e.currency) {
                            assets.forEach((ele) => {
                              if (e.currency === ele.id)
                                Unallocated = ele.total;
                              if (e.currency === "USD") Unallocated = getfiat;
                              if (e.currency === "ETH") Unallocated = geteth;
                            });
                            investmentDetails.forEach((ele1) => {
                              if (ele1.id === Number(ele.investment_id)) {
                                var effectiveDate = new Date(
                                  ele1.effectiveDate
                                );
                                var returnDate = new Date(ele1.expiryDate);
                                var date = new Date();
                                var currDate = new Date(
                                  date.getFullYear() +
                                    "-" +
                                    (date.getMonth() + 1) +
                                    "-" +
                                    date.getDate()
                                );

                                const diffTime = Math.abs(
                                  returnDate - effectiveDate
                                );
                                const diffCurrTime = Math.abs(
                                  currDate - effectiveDate
                                );

                                const diffDays =
                                  ele1.structure === "OT"
                                    ? Math.ceil(
                                        diffCurrTime / (1000 * 60 * 60 * 24)
                                      )
                                    : Math.ceil(
                                        diffTime / (1000 * 60 * 60 * 24)
                                      );

                                currDaysDiff = Math.ceil(
                                  diffCurrTime / (1000 * 60 * 60 * 24)
                                );
                                if (
                                  diffDays > currDaysDiff ||
                                  ele1.structure === "OT"
                                ) {
                                  currDaysDiff = Math.ceil(
                                    diffCurrTime / (1000 * 60 * 60 * 24)
                                  );
                                } else {
                                  currDaysDiff = diffDays;
                                }
                              }
                            });
                            sum =
                              sum +
                              (Number(ele.quantity) +
                                Number(ele.daily_interest) * currDaysDiff) +
                              (duplicateCount === 0 ? Number(Unallocated) : 0);
                            duplicateCount++;
                          }
                        });

                        var filterClietpool = clientpool.filter((ele) => {
                          if (ele.currency === e.currency) return ele;
                        });
                        if (e.currency === "ETH") {
                          allClientPoolCurrecy.push(e.currency);
                          return (
                            <div
                            style={{ width: "100%", position: "relative" }}
                            onClick={() =>
                              navigate("/Tab", { state: { id: "ETH" } })
                            }
                          >
                            <img
                              style={{ objectFit: "contain", width: "100%" }}
                              alt="ETH"
                              src={ETHD}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "10%",
                              }}
                            >
                              <img
                                src={Eth}
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                            <Space
                              direction="vertical"
                              size={0}
                              className="valueD"
                            >
                              <div>
                                <Text className="textId">
                                 ETH
                                </Text>
                              </div>
                              <div>
                                <Text className="valueId">
                                {Number(sum).toFixed(4)}
                                </Text>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    height: "5px",
                                    width: `${(Unallocated / sum) * 100}%`,
                                    backgroundColor: "#38DEB6",
                                  }}
                                >
                                  {" "}
                                </div>
                                {filterClietpool.map((e1, i) => {
                                  if (e1.currency === e.currency) {
                                    return (
                                      <div
                                        key={i}
                                        style={{
                                          display: "flex",
                                          height: "5px",
                                          width: `${
                                            (e1.quantity / sum) * 100
                                          }%`,
                                          backgroundColor: `${colors[i + 1]}`,
                                        }}
                                      ></div>
                                    );
                                  }
                                })}
                              </div>
                            </Space>
                          </div>
                          );
                        }
                        if (
                          bigCardName !== e.currency &&
                          e.currency !== "ETH"
                        ) {
                          allClientPoolCurrecy.push(e.currency);
                          return (
                            <div
                            style={{ width: "100%", position: "relative" }}
                            onClick={() =>
                              navigate("/Tab", { state: { id: e.currency } })
                            }
                          >
                            <img
                              style={{ objectFit: "contain", width: "100%" }}
                              alt={e.currency}
                              src={e.currency === "BTC"
                              ? BTCD
                              : e.currency === "USDC"
                              ? USDCD
                              : USDTD}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "10%",
                              }}
                            >
                              <img
                                src={e.currency === "BTC"
                                ? Bit
                                : e.currency === "USDC"
                                ? Usdc
                                : Bnb}
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                            <Space
                              direction="vertical"
                              size={0}
                              className="valueD"
                            >
                              <div>
                                <Text className="textId">
                                {e.currency === "USDT_ERC20"
                                        ? "USDT"
                                        : e.currency}
                                </Text>
                              </div>
                              <div>
                                <Text className="valueId">
                                {Number(sum).toFixed(e.currency==='BTC'?8:4)}
                                </Text>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    height: "5px",
                                    width: `${(Unallocated / sum) * 100}%`,
                                    backgroundColor: "#38DEB6",
                                  }}
                                >
                                  {" "}
                                </div>
                                {filterClietpool.map((e1, i) => {
                                  if (e1.currency === e.currency) {
                                    return (
                                      <div
                                        key={i}
                                        style={{
                                          display: "flex",
                                          height: "5px",
                                          width: `${
                                            (e1.quantity / sum) * 100
                                          }%`,
                                          backgroundColor: `${colors[i + 1]}`,
                                        }}
                                      ></div>
                                    );
                                  }
                                })}
                              </div>
                            </Space>
                          </div>
                          );
                        }
                      })}
                    </div>
                    <div>
                      {filterETH[0] === undefined ? (
                         <div
                         style={{ width: "100%", position: "relative" }}
                         onClick={() =>
                           navigate("/Tab", { state: { id: "ETH" } })
                         }
                       >
                         <img
                           style={{ objectFit: "contain", width: "100%" }}
                           alt="ETH"
                           src={ETHD}
                         />
                         <div
                           style={{
                             position: "absolute",
                             right: "5%",
                             top: "10%",
                           }}
                         >
                           <img
                             src={Eth}
                             style={{ width: "50px", height: "50px" }}
                           />
                         </div>
                         <Space
                           direction="vertical"
                           size={0}
                           className="valueD"
                         >
                           <div>
                             <Text className="textId">ETH</Text>
                           </div>
                           <div>
                             <Text className="valueId">
                             {Number(geteth).toFixed(4)}
                             </Text>
                           </div>
                           <div
                             style={{
                               height: "5px",
                               width: "100%",
                               marginTop:"10px",
                               backgroundColor: `${colors[0]}`,
                             }}
                           ></div>
                         </Space>
                       </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      {assets.map((e, i) => {
                        if (
                          e.id === "USDT_ERC20" ||
                          e.id === "BTC" ||
                          e.id === "USDC"
                        )
                          if (!allClientPoolCurrecy.includes(e.id)) {
                            return (
                              <div
                              style={{ width: "100%", position: "relative" }}
                              onClick={() =>
                                navigate("/Tab", {
                                  state: { id: e.id },
                                })
                              }
                            >
                              <img
                                style={{ objectFit: "contain", width: "100%" }}
                                alt={e.id}
                                src={
                                e.id === "BTC"
                                ?BTCD
                                :e.id === "USDC"
                                ?USDCD
                                :USDTD
                              }
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  right: "5%",
                                  top: "10%",
                                }}
                              >
                                <img
                                  src={     e.id === "BTC"
                                  ? Bit
                                  : e.id === "USDC"
                                  ? Usdc
                                  : Bnb}
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <Space
                                direction="vertical"
                                size={0}
                                className="valueD"
                              >
                                <div>
                                  <Text className="textId">{e.id === "USDT_ERC20" ? "USDT" : e.id}</Text>
                                </div>
                                <div>
                                  <Text className="valueId">
                                  {Number(e.available).toFixed(4)}
                                  </Text>
                                </div>
                                <div
                                  style={{
                                    height: "5px",
                                    width: "100%",
                                    marginTop:"10px",
                                    backgroundColor: `${colors[0]}`,
                                  }}
                                ></div>
                              </Space>
                            </div>
                            );
                          }
                      })}
                    </div>
                  </Space>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
};

export default Dashboard;
