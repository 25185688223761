import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import "./Signin.css";
import { supabase } from "../../../supabaseClient";
import { useNavigate } from "react-router-dom";
import Logopane from "../../components/Sidebar/Logopane";
import errorIcon from "../../../Assets/error.svg";

const { Text } = Typography;
const Signin = () => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [account, setAccount] = useState(true);
  let navigate = useNavigate();
  const onSubmit = async (values) => {
    await supabase
      .from("profile")
      .select("*")
      .match({ email: values.email.toLowerCase() })
      .then(async ({ data}) => {
        if (data[0] !== undefined) {
          if (data[0].block_count < 0) {
            navigate("/maxtryerror");
          } else {
            const {error } = await supabase.auth.signIn({
              email: values.email.toLowerCase(),
              password: values.password,
            });
            if (!error) {
              setError(false);
              supabase
                .from("profile")
                .update({ block_count: 3 })
                .match({ email: values.email.toLowerCase() })
                .then(() => {});

              supabase
                .from("two_factor_auth")
                .select("two_fa")
                .match({ user_id: supabase.auth.user().id })
                .then(({ data}) => {
                  if (data[0] !== undefined) {
                    if (data[0].two_fa === "true") {
                      navigate("/otpsignin");
                    } else {
                      navigate("/dashboard");
                    }
                  } else {
                    navigate("/dashboard");
                  }
                });
            } else {
              supabase
                .from("profile")
                .select()
                .match({ email: values.email.toLowerCase() })
                .then(({ data}) => {
                  if (data[0] !== undefined) {
                    setError(true);
                    setAccount(true);
                    setErrorMessage(
                      `You have ${data[0].block_count} tries left`
                    );
                    var count = data[0].block_count;
                    supabase
                      .from("profile")
                      .update({ block_count: Number(count) - 1 })
                      .match({ email: values.email.toLowerCase() })
                      .then(({ data}) => {
                        if (data[0] !== undefined) {
                          if (data[0].block_count < 0) {
                            navigate("/maxtryerror");
                            setErrorMessage("");
                          }
                        }
                      });
                  }
                });
            }
          }
        } else {
          setAccount(false);
          setError(true);
          setErrorMessage("Please activate your account");
        }
      });
  };

  return (
    <div className="signContainer">
      <div className="sidebar">
        <Logopane />
      </div>
      <div className="signIn">
        <div className="PhoneLogo" style={{ height: "10%" }}>
          <Logopane />
        </div>
        <div className="head">
          <Text className="header-text">Welcome Back</Text>
        </div>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
        >
          <div style={{ marginTop: "5.8vh" }}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                bordered={false}
                className="input-div"
                type="email"
                placeholder="Email"
              />
            </Form.Item>
          </div>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              bordered={false}
              className="input-div"
              placeholder="Password"
            />
          </Form.Item>
          {error ? (
            <div className="error-container">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <img
                    src={errorIcon}
                    style={{ width: "27px", height: "27px" }}
                  />
                </div>
                <div style={{ marginLeft: "5px" }}>
                  {account ? (
                    <div>
                      <Text className="error-text">
                        Email or Password is incorrect.
                      </Text>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div>
                    <Text className="error-text">{errorMessage}</Text>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* <div
            style={{
              marginTop: "1.6vh",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link to="/forgotpassword">
              <Text className="forgot-password">Forgot password?</Text>
            </Link>
          </div> */}
          <div
            style={{
              marginTop: "9.87vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Form.Item>
              <Button htmlType="submit" className="signupBtn">
                Log In
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className="first-container">
          <Text className="first-text">First time User?</Text>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button className="signupBtn" onClick={() => navigate("/signup")}>
            Tap to activate account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Signin;
