
import logo from "../../../Assets/ryki.svg";
import "./Sidebar.css";

const Logopane = () => {

    return (
        <div className="logo-container">
        <img src={logo}/>
        </div>
    );
};




export default Logopane;