import React, { useState } from "react";
import { Button, Typography, Space } from "antd";
import Return from "../../components/Return/Return";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../supabaseClient";
import emaillogo from "../../../Assets/email.svg";
import phone from "../../../Assets/phone.svg";
import "./Settings.css";

const Settings = () => {
  const [showPopup, setshowPopup] = useState("none");
  let navigate = useNavigate();
  const { Text } = Typography;
  return (
    <div className="container">
      <div
        style={{
          position: "absolute",
          display: `${showPopup}`,
          top: "5%",
          left: "55%",
          background: "white",
          boxShadow:
            "5.537px 4.4296px 11.074px rgb(0 0 0 / 25%), inset 1.1074px 1.1074px 0px rgb(255 255 255 / 25%)",
          borderRadius: "33px",
          zIndex: 111,
          width: "300px",
          height: "150px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign:"center"
          }}
        >
          <Text style={{color:"black",fontSize:'18px',fontWeight:"600"}}>You have already Enabled 2FA</Text>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div>
            <Button
              style={{
                width: "80px",
                height: "50px",
                backgroundColor: "black",
                boxShadow:
                  "5.537px 4.4296px 11.074px rgb(0 0 0 / 25%), inset 1.1074px 1.1074px 0px rgb(255 255 255 / 25%)",
                borderRadius: "33px",
                color: "white",
              }}
              onClick={async () => {
                const { error } = await supabase
                  .from("two_factor_auth")
                  .delete()
                  .match({ user_id: supabase.auth.user().id });
                if (!error) {
                  setshowPopup("none");
                } else {
                  console.log(error);
                }
              }}
            >
              Disable
            </Button>
          </div>
          <div>
            <Button
              style={{
                width: "80px",
                height: "50px",
                backgroundColor: "black",
                boxShadow:
                  "5.537px 4.4296px 11.074px rgb(0 0 0 / 25%), inset 1.1074px 1.1074px 0px rgb(255 255 255 / 25%)",
                borderRadius: "33px",
                color: "white",
              }}
              onClick={() => {
                setshowPopup("none");
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <div className="backScreen">
        <Return />
      </div>
      <div className="settings-container">
        <div style={{ marginTop: "170px" }}>
          <div style={{ textAlign: "start" }}>
            <Text
              style={{
                fontSize: "1.39vw",
                fontFamily: "SemiBold",
                color: "white",
              }}
            >
              Profile
            </Text>
          </div>
          <Space
            direction="vertical"
            style={{
              height: "35vh",
              marginTop: "2.53%",
            }}
            size="large"
          >
            <div>
              <Button
                className="setBtn"
                htmlType="submit"
                onClick={() => navigate("/changepassword")}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Text className="setText">Change Password</Text>
                  </div>
                  <div>
                    <RightOutlined style={{ color: "white" }} />
                  </div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                className="setBtn"
                htmlType="submit"
                onClick={async () => {
                  await supabase
                    .from("two_factor_auth")
                    .select()
                    .match({ user_id: supabase.auth.user().id })
                    .then(({ data}) => {
                      if (data[0] === undefined) {
                        navigate("/twoFA");
                      } else {
                        if (data[0].two_fa === "false") navigate("/twoFA");
                        if (data[0].two_fa === "true") {
                          setshowPopup("block");
                        }
                      }
                    });
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Text className="setText">Enable/Disable 2FA</Text>
                  </div>
                  <div>
                    <RightOutlined style={{ color: "white" }} />
                  </div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                className="setBtn"
                htmlType="submit"
                onClick={() => navigate("/FAQ")}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Text className="setText">Frequently Asked Questions</Text>
                  </div>
                  <div>
                    <RightOutlined style={{ color: "white" }} />
                  </div>
                </div>
              </Button>
            </div>
            {/* <div>
              <Button
                className="setBtn"
                htmlType="submit"
                onClick={() => navigate("/changeemail")}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Text
                      className="setText"
                    >
                      Change Registered Email
                    </Text>
                  </div>
                  <div>
                    <RightOutlined style={{ color: "white" }} />
                  </div>
                </div>
              </Button>
            </div> */}
            {/* <div>
              <Button
                className="setBtn"
                htmlType="submit"
                onClick={() => navigate("/changephone")}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Text
                      className="setText"
                    >
                      Change Registered Phone number
                    </Text>
                  </div>
                  <div>
                    <RightOutlined style={{ color: "white" }} />
                  </div>
                </div>
              </Button>
            </div> */}
          </Space>
          {/* <div style={{ marginTop: "6vh" }}>
          </div> */}
          <div
            style={{
              marginTop: "10vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <Text
                style={{
                  fontSize: "1.25vw",
                  fontFamily: "DisRegular",
                  color: "white",
                }}
              >
                For any kind of support,please contact:
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "4vh",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <img src={emaillogo} />
                </div>
                <div style={{ marginLeft: "1vw" }}>
                  <Text className="contact">support@ryki.io</Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "5vw",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={phone} />
                </div>
                <div style={{ marginLeft: "1vw" }}>
                  <Text className="contact">999.999.999</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
