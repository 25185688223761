import React, { useState, useEffect } from "react";
import Return from "../../components/Return/Return";
import { Typography } from "antd";
import plus from "../../../Assets/plus.svg";
import minus from "../../../Assets/minus.svg";

const Faq = () => {
  const { Text } = Typography;
  const [showans, setShowans] = useState("none");
  const [findindex, setIndex] = useState();
  const arr = [
    {
      id: 0,
      question: "What are the services do you offer",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates corporis vitae tempora quod provident tenetur culpa dolore facere? Earum, dolor?",
    },
    {
      id: 1,
      question: "what are our preferred method of payment",
      answer:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Architecto iusto veniam eveniet labore impedit nam",
    },
    {
      id: 2,
      question: "Are your services beginners friendly",
      answer:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores,",
    },
    {
      id: 3,
      question: "what how does it take to upgrade a package",
      answer:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores, sequi.",
    },
    {
      id: 4,
      question: "Where are your offices located around the world",
      answer:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, sed. Dolores, sequi.",
    },
  ];
  const showAns = (index) => {
    setIndex(index);
    showans === "flex" ? setShowans("none") : setShowans("flex");
  };
  useEffect(() => {
    setShowans("flex");
  }, [findindex]);
  return (
    <div className="container">
      <div className="backScreen">
        <Return />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "65%",
          backgroundColor: "#0F1E2E",
          height: "100%",
        }}
      >
        <div style={{ marginTop: "12vh", marginBottom: "2vh" }}>
          <Text
            style={{
              fontSize: "20px",
              fontWeight: "600",
              fontFamily: "SF Pro Display",
              color: "white",
            }}
          >
            Frequently Asked Questions
          </Text>
        </div>
        <div style={{ height: "100%" }}>
          {arr.map((ele, index) => {
            return (
              <div
                key={index}
                style={{
                  marginBottom: "3vh",
                  padding: "12px",
                  width: "540px",
                  backgroundColor: "#ffffff0a",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "white",
                    }}
                  >
                    {ele.question}
                  </Text>
                  <Text
                    onClick={() => {
                      showAns(index, ele.id);
                    }}
                  >
                    {showans === "flex" && index === findindex ? (
                      <img src={minus} />
                    ) : (
                      <img src={plus} />
                    )}
                  </Text>
                </div>
                {index === findindex ? (
                  <div
                    style={{
                      display: `${showans}`,
                      backgroundColor: "#0E1E2E",
                      color: "white",
                      marginTop: "5%",
                    }}
                  >
                    <Text style={{ padding: "10px", color: "white" }}>
                      {ele.answer}
                    </Text>
                  </div>
                ) : (
                  <div
                    style={{
                      display: `none`,
                      backgroundColor: "#0E1E2E",
                      color: "white",
                      marginTop: "5%",
                    }}
                  >
                    <Text style={{ padding: "10px", color: "white" }}>
                      {ele.answer}
                    </Text>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;
