import React, { useState } from "react";
import { Input, Button, Typography, message } from "antd";
import "./Signup.css";
import { supabase } from "../../../supabaseClient";
import { useNavigate } from "react-router-dom";
import Logopane from "../../components/Sidebar/Logopane";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;
const Signup = () => {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [count, setCount] = useState(1);
  const [error, setError] = useState(false);
  let navigate = useNavigate();

  const onSubmit = async () => {
    const {error} = await supabase.auth.verifyOTP({
      email: email,
      token: otp,
      type: "signup",
    });
    if (error) {
      setError(true);
      if (error.message === "Token has expired or is invalid") {
        navigate("/otperror");
      }
      setCount(count + 1);
      if (count > 3) {
        navigate("/maxtryerror");
        setCount(0);
      }
    } else {
      message.success("Account created Successfully");
      setEmail("");
      setOTP("");
      navigate("/setpassword");
    }
  };
  return (
    <div className="signContainer">
      <div className="sidebar">
        <Logopane />
      </div>
      <div className="signIn">
        <div>
          <Text className="header-text">Let’s get started!</Text>
        </div>
        <div style={{ marginTop: "5.8vh" }}>
          <Input
            bordered={false}
            className="input-div"
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div style={{ marginTop: "3.46vh" }}>
          <Input
            bordered={false}
            className="input-div"
            placeholder="OTP"
            onChange={(e) => setOTP(e.target.value)}
          />
        </div>
        {error ? (
          <div style={{ display: "flex", alignItems: "center", width: "12vw" }}>
            <div>
              <ExclamationCircleOutlined
                style={{ color: "rgba(248, 79, 68, 1)", fontSize: "22px" }}
              />
            </div>
            <div style={{ marginLeft: "5%" }}>
              <div>
                <Text
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "white",
                    fontFamily: "SF Pro Display",
                  }}
                >
                  Incorrect OTP.
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "white",
                    fontFamily: "SF Pro Display",
                  }}
                >
                  You have {count >= 5 ? 0 : 5 - count} tries left
                </Text>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div style={{ marginTop: "11vh" }}>
          <Button className="signupBtn" onClick={() => onSubmit()}>
            Continue
          </Button>
        </div>
        <div className="first-container">
          <Text className="first-text">Already have an account?</Text>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button className="signupBtn" onClick={() => navigate("/signin")}>
            Go Back to Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
