import React from "react";
import { Typography} from "antd";
import logo from "../../../Assets/Logo.svg";
import { useNavigate} from "react-router-dom";
import back from "../../../Assets/circle-arrow-left.svg";
import "./Return.css";

const { Text } = Typography;
function Return() {
    let navigate = useNavigate();
    return (
        <>
            <div className="return-container">
                <div style={{ position: "absolute", top: "9.37vh", left: "9.34vw" }}>
                    <div>
                        <img src={logo} style={{width:"147px",height:"49px"}} />
                    </div>
                    <div className="back">
                        <div onClick={()=>navigate(-1)}>
                            <img src={back} />
                        </div>
                        <div style={{ marginLeft: "1vw" }}>
                            <Text className="back-text">Back</Text>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Return;
