import React from "react";
import { Typography } from "antd";
import Support from "../../../components/Support/Support";
import Return from "../../../components/Return/Return";
import error from "../../../../Assets/error.svg";
import "./Error.css";

const Maxtryerror = () => {
  const { Text } = Typography;

  return (
    <div className="container">
      <div className="backScreen">
        <Return />
      </div>
      <div className="backContent">
        <div className="message-container">
          <div>
            <img src={error} />
          </div>
          <div style={{ marginTop: "1.22vh" }}>
            <Text className="message">Max tries reached</Text>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Text className="message-warning">
              Your account has been temporarily blocked
            </Text>
          </div>
          <div style={{ marginTop: "7.54vh" }}>
            <Support />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maxtryerror;
