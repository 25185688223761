import Header from "../../components/Header/Header";
import { Form, Input, Button, Typography, message } from "antd";
import { supabase } from "../../../supabaseClient";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const user1 = supabase.auth.user();
  const [current, setCurrent] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const { Text } = Typography;
  let navigate = useNavigate();
  const onSubmit = async () => {
    const { error } = await supabase.auth.signIn({
      email: user1.email,
      password: current,
    });
    if (!error) {
      const { error } = supabase.auth.update({
        password: newpassword,
      });
      if (!error) {
        message.success("Password reset succesfully");
        navigate("/settings");
      }
    } else {
      console.log("Error", error);
      setError("Current Password is incorrect. Could not set new password.");
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <Header />
      <div
        style={{
          backgroundColor: "#0F1E2E",
          height: "88.8vh",
        }}
      >
        <div style={{ paddingTop: "8vh", marginLeft: "25vw" }}>
          <div>
            <Text className="title">Change Password</Text>
          </div>
          <div style={{ marginTop: "1vh" }}>
            <Text className="info">
              The <strong>new password</strong> should be alpha-numeric and at
              least 8 characters long
            </Text>
          </div>
          <div style={{ marginLeft: "10vw" }}>
            <Form
              style={{ marginTop: "10vh" }}
              layout="vertical"
              name="changepassword"
              className="changepassword-form"
              initialValues={{
                remember: true,
              }}
            >
              <Form.Item
                name="current_password"
                style={{ marginBottom: "5vh" }}
              >
                <Input
                  bordered={false}
                  className="input-div"
                  placeholder="Current Password"
                  onChange={(e) => setCurrent(e.target.value)}
                />
                <div>
                  <Text
                    style={{
                      fontSize: "0.97vw",
                      fontFamily: "Regular",
                      color: "#C55C5C",
                    }}
                  >
                    {error}
                  </Text>
                </div>
              </Form.Item>
              <Form.Item
                name="new_password"
                style={{ marginBottom: "5vh" }}
                hasFeedback
                rules={[
                  {
                    required: true,
                    pattern: new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})"),
                    message: "Please enter an valid password!",
                  },
                ]}
              >
                <Input.Password
                  bordered={false}
                  className="input-div"
                  placeholder="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="confirm_password"
                dependencies={["new_password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  bordered={false}
                  className="input-div"
                  placeholder="Confirm New Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item style={{ paddingTop: "6vh", marginLeft: "4vw" }}>
                <Button
                  className="signupBtn"
                  htmlType="submit"
                  onClick={() => onSubmit()}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </div>
          {success ? (
            <div style={{ paddingTop: "4vh", marginLeft: "4vw" }}>
              <Button
                className="signupBtn"
                onClick={() => navigate("/Settings")}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: "600",
                    textAlign: "center",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  Password Changed Sucessfully!
                </Text>
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
