import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import logo from "../../../Assets/splash.svg";
import { Spin } from 'antd';

const Loader = () => {
    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 40,
          }}
          spin
        />
      );
  return (
    <div style={{height:"100vh",width:"100%",backgroundColor:"#0C1A28",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
    <div>
    <img src={logo}/>
    </div>
    <div style={{marginTop:"5vh"}}>
    <Spin indicator={antIcon} />
    </div>
    </div>
  )
}

export default Loader