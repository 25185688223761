import React, { useState, useEffect } from "react";
import { Typography,Divider,Button } from "antd";
import { Line } from "react-chartjs-2";
import { Spin } from "antd";
import uparrow from "../../../Assets/uparrow.svg";
import downarrow from "../../../Assets/downarrow.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import "./Market.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const Market = (props) => {
  const [loading, setLoading] = useState(true);
  const [range, setChange] = useState("all");
  const [marketDetails, setmarketDetails] = useState([]);
  const [getClosedPrice, setClosedPrice] = useState([]);
  const [filterClosePrice, setfilterClosePrice] = useState([]);
  const [gettimestamp, settimestamp] = useState([]);
  useEffect(() => {
    getmarketprice("all");
  }, []);
  const getmarketprice = async (e) => {
    setChange(e);

    if (e === "one_day") {
      setfilterClosePrice(
        getClosedPrice.filter((e, i) => {
          if (i < 3) {
            return e;
          }
        })
      );
    } else if (e === "one_week") {
      setfilterClosePrice(
        getClosedPrice.filter((e, i) => {
          if (i < 5) {
            return e;
          }
        })
      );
    } else if (e === "one_month") {
      setfilterClosePrice(
        getClosedPrice.filter((e, i) => {
          if (i < 7) {
            return e;
          }
        })
      );
    } else if (e === "one_year") {
      setfilterClosePrice(getClosedPrice);
    } else {
      setfilterClosePrice(getClosedPrice);
    }
  };
  const data = {
    labels: gettimestamp,
    datasets: [
      {
        label: "Price",
        data: filterClosePrice,
        fill: true,
        borderColor: "rgba(77,255,223, 1)",
        tension: 0.1,
        pointRadius: 0,
        borderWidth: 2,
        backgroundColor: "#cccccc",
      },
    ],
  };
  const options = {
    plugins: { legend: { display: false } },
    scales: {
      x: {
        ticks: {
          display: false,
        },

        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    try {
      axios
        .post("/api/market", {
          data: props.id,
        })
        .then((res) => {
          setClosedPrice([]);
          settimestamp([]);
          setmarketDetails(res.data.coinDetails.data);
          Object.values(res.data.coindata.data.quotes).forEach((curr) => {
            settimestamp((gettimestamp) => [
              ...gettimestamp,
              curr.quote.USD.timestamp,
            ]);
            setClosedPrice((getClosedPrice) => [
              ...getClosedPrice,
              curr.quote.USD.close.toFixed(2),
            ]);
            setfilterClosePrice((filterClosePrice) => [
              ...filterClosePrice,
              curr.quote.USD.close.toFixed(2),
            ]);
          });
          setLoading(false);
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const { Text } = Typography;
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <Spin size="large" />
    </div>
  ) : (
    <div>
      <div style={{ display: "flex", marginLeft: "12.6vw", marginTop: "10vh" }}>
        <div>
          {marketDetails.map((ele, i) => {
            if (ele.symbol === props.id)
              return (
                <div
                 className="graph-container"
                >
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text className="graph">
                      {ele.name}

                      <span style={{ fontSize: "17.12px" }}>
                        <img
                          src={
                            ele.quote.USD.percent_change_24h > 0
                              ? uparrow
                              : downarrow
                          }
                          alt=""
                        />{" "}
                        <span
                          style={{
                            color:
                              ele.quote.USD.percent_change_24h > 0
                                ? "#38DEB6"
                                : "#DE4238",
                          }}
                        >
                          {" "}
                          {ele.quote.USD.percent_change_24h.toFixed(4)}
                        </span>
                      </span>
                    </Text>

                    <div style={{ width: "80%" }}>
                      <Line
                        style={{ color: "red" }}
                        data={data}
                        options={options}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                    }}
                  >
                    <div>
                      <Button
                        onClick={() => getmarketprice("one_day")}
                        style={{
                          width: "77px",
                          height: "37.6px",
                          backgroundColor:
                            range === "one_day"
                              ? "rgba(0, 0, 0, 0.36)"
                              : "rgba(0, 0, 0, 0.0)",
                          color: "white",
                          borderRadius: "28px",
                          fontSize: "16.97px",
                          fontFamily: "SBold",
                          lineHeight: "24px",
                          letterSpacing: "-0.606397px",
                        }}
                      >
                        1 D
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => getmarketprice("one_week")}
                        style={{
                          width: "77px",
                          height: "37.6px",
                          backgroundColor:
                            range === "one_week"
                              ? "rgba(0, 0, 0, 0.36)"
                              : "rgba(0, 0, 0, 0.0)",
                          color: "white",
                          borderRadius: "28px",
                          fontSize: "16.97px",
                          fontFamily: "SBold",
                          lineHeight: "24px",
                          letterSpacing: "-0.606397px",
                        }}
                      >
                        1 W
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => getmarketprice("one_month")}
                        style={{
                          width: "77px",
                          height: "37.6px",
                          backgroundColor:
                            range === "one_month"
                              ? "rgba(0, 0, 0, 0.36)"
                              : "rgba(0, 0, 0, 0.0)",
                          color: "white",
                          borderRadius: "28px",
                          fontSize: "16.97px",
                          fontFamily: "SBold",
                          lineHeight: "24px",
                          letterSpacing: "-0.606397px",
                        }}
                      >
                        1 M
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => getmarketprice("one_year")}
                        style={{
                          width: "77px",
                          height: "37.6px",
                          backgroundColor:
                            range === "one_year"
                              ? "rgba(0, 0, 0, 0.36)"
                              : "rgba(0, 0, 0, 0.0)",
                          color: "white",
                          borderRadius: "28px",
                          fontSize: "16.97px",
                          fontFamily: "SBold",
                          lineHeight: "24px",
                          letterSpacing: "-0.606397px",
                        }}
                      >
                        1 Y
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => getmarketprice("all")}
                        style={{
                          width: "77px",
                          height: "37.6px",
                          backgroundColor:
                            range === "all"
                              ? "rgba(0, 0, 0, 0.36)"
                              : "rgba(0, 0, 0, 0.0)",
                          color: "white",
                          borderRadius: "28px",
                          fontSize: "16.97px",
                          fontFamily: "SBold",
                          lineHeight: "24px",
                          letterSpacing: "-0.606397px",
                        }}
                      >
                        All
                      </Button>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
        <div style={{ marginLeft: "4.65vw", width: "438px" }}>
          <div>
            <Text className="btcS">{props.id} Price Statistics</Text>
          </div>
          <div style={{width: "438px"}}>
            {marketDetails.map((ele, i) => {
              if (ele.symbol === props.id)
                return (
                  <div style={{ marginTop: "4.5vh" }}>
                    <div>
                      <Text className="currency">{ele.name} Price Today</Text>
                      <Divider style={{ backgroundColor: "white" }} plain />
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text className="property">{ele.name} Price</Text>
                        <Text className="priceText">
                          $
                          {ele.quote.USD.price
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </div>
                      <Divider style={{ backgroundColor: "white" }} plain />
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text className="property">Price Change (24hr)</Text>
                        <Text className="priceText">
                          {ele.quote.USD.percent_change_24h < 0
                            ? (
                                "$" +
                                (
                                  ele.quote.USD.price *
                                  (ele.quote.USD.percent_change_24h / 100)
                                ).toFixed(2)
                              )
                                .split("$-")
                                .join("-$")
                            : "$" +
                              (
                                ele.quote.USD.price *
                                (ele.quote.USD.percent_change_24h / 100)
                              )
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </div>
                      <Divider style={{ backgroundColor: "white" }} plain />
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text className="property">24 Low / 24h High</Text>
                        <Text className="priceText">
                          $
                          {ele.quote.USD.price *
                            (ele.quote.USD.percent_change_24h / 100) <
                          0
                            ? (
                                ele.quote.USD.price +
                                ele.quote.USD.price *
                                  (ele.quote.USD.percent_change_24h / 100)
                              )
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : ele.quote.USD.price
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          / $
                          {ele.quote.USD.price *
                            (ele.quote.USD.percent_change_24h / 100) >
                          0
                            ? (
                                ele.quote.USD.price +
                                ele.quote.USD.price *
                                  (ele.quote.USD.percent_change_24h / 100)
                              )
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : ele.quote.USD.price
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </div>
                      <Divider style={{ backgroundColor: "white" }} plain />
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text className="property">Trading Volume</Text>
                        <Text className="priceText">
                          $
                          {ele.quote.USD.volume_24h
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </div>
                      <Divider style={{ backgroundColor: "white" }} plain />
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;
