import React, { useState, useEffect } from "react";
import { Typography, Button, Input } from "antd";
import ProgressStep from "../../components/ProgressStep/ProgressStep";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../../../supabaseClient";
import axios from "axios";
import "./TwoFA.css";
const { Text } = Typography;

const TwoFAIp = () => {
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [secretkey, setSecretkey] = useState("");
  useEffect(() => {
    setSecretkey(location.state.secretkey);
  }, [secretkey]);

    const authentication = async () => {
      const { error } = await supabase
        .from("two_factor_auth")
        .insert([
          {
            user_id: supabase.auth.user().id,
            two_fa: "true",
            tfa_secretkey: secretkey,
          },
        ]);
      if (!error) {
        await axios
          .get(`${process.env.REACT_APP_CLOUD_FUNCTION}?otp=${otp}&secret=${supabase.auth.session().access_token}`)
          .then(async (response) => {
            if (response.data === true) {
              setError(false);
              navigate("/signin");
            } else {
              setError(true);
            }
          });
      }
  
    };

  const current = 3;
  let navigate = useNavigate();
  return (
    <div className="container">
      <ProgressStep current={current} />
      <div
        style={{ width: "71vw", height: "100%", backgroundColor: "#0F1E2E" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20vh",
          }}
        >
          <div style={{ marginBottom: "1.32vh" }}>
            <Text className="title">2FA Verification</Text>
          </div>
          <div
            style={{
              marginTop: "2.24vh",
              width: "24.5vw",
              height: "6.72vh",
              textAlign: "center",
            }}
          >
            <Text className="info">
              Enter the OTP you see on your Google Authenticator App
            </Text>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10vh",
          }}
        >
          <Input
            bordered={false}
            className="input-div"
            placeholder="OTP"
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
        {error ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2.24vh",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Text className="info" style={{ color: "white" }}>
                The OTP you entered is incorrect
              </Text>
            </div>
            <div style={{ textAlign: "center" }}>
              <Text className="info" style={{ color: "white" }}>
                Please try again.
              </Text>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "23vh",
          }}
        >
          <Button className="signupBtn" onClick={() => authentication()}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TwoFAIp;
