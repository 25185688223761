import React, { useEffect, useState } from "react";
import "./SecQA.css";
import ProgressStep from "../../components/ProgressStep/ProgressStep";
import { Typography, Select, Input,Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../supabaseClient";

const { Option } = Select;
const { Text } = Typography;

export default function SecQA() {
  const current = 2;
  let navigate = useNavigate();
  const [allSeqQutions, setAllSeqQutions] = useState([]);
  const [sqcQuation1, setSeqQution1] = useState([]);
  const [sqcQuation2, setSeqQution2] = useState([]);
  const [sqcQuation3, setSeqQution3] = useState([]);
  const [selQuation1, setSelQuation1] = useState(null);
  const [selQuation2, setSelQuation2] = useState(null);
  const [selQuation3, setSelQuation3] = useState(null);
  const [ans1, setAns1] = useState("");
  const [ans2, setAns2] = useState("");
  const [ans3, setAns3] = useState("");

  useEffect(() => {
    {
      supabase
        .from("security_questions")
        .select("*")
        .then(({ data}) => {
          setSeqQution1(data);
          setSeqQution2(data);
          setSeqQution3(data);
          setAllSeqQutions(data);
        });
    }
  }, []);

  useEffect(() => {
    {
      setSeqQution2([
        ...allSeqQutions.filter(
          (v) => v.id !== selQuation1 && v.id !== selQuation3
        ),
      ]);
      setSeqQution3([
        ...allSeqQutions.filter(
          (v) => v.id !== selQuation2 && v.id !== selQuation1
        ),
      ]);
    }
  }, [selQuation1]);
  useEffect(() => {
    {
      setSeqQution1([
        ...allSeqQutions.filter(
          (v) => v.id !== selQuation2 && v.id !== selQuation3
        ),
      ]);
      setSeqQution3([
        ...allSeqQutions.filter(
          (v) => v.id !== selQuation2 && v.id !== selQuation1
        ),
      ]);
    }
  }, [selQuation2]);
  useEffect(() => {
    {
      setSeqQution1([
        ...allSeqQutions.filter(
          (v) => v.id !== selQuation2 && v.id !== selQuation3
        ),
      ]);
      setSeqQution2([
        ...allSeqQutions.filter(
          (v) => v.id !== selQuation3 && v.id !== selQuation1
        ),
      ]);
    }
  }, [selQuation3]);

  const onChange1 = (value) => {
    setSelQuation1(value);
  };
  const onChange2 = (value) => {
    setSelQuation2(value);
  };
  const onChange3 = (value) => {
    setSelQuation3(value);
  };
  const setAnswers = async () => {
    if (ans1 !== "" && ans2 !== "" && ans3 !== "") {
     await supabase
        .from("user_selected_answers")
        .insert([
          { user_id: supabase.auth.user().id, answer: [ans1, ans2, ans3] },
        ])
        .then(({ error }) => {
          if (!error) {
            navigate("/twoFA");
          } else {
            message.error(error);
          }
        });
    }
  };
  return (
    <div className="container">
      <div>
        <ProgressStep current={current} />
      </div>
      <div className="secContainer">
        <div
          style={{
            marginTop: "8.67vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <Text className="info">
              Choose 3 security questions and enter their answers
            </Text>
          </div>
          <div style={{ marginTop: "2.24vh" }}>
            <Text className="info-text">
              This will help in password recovery
            </Text>
          </div>
          <div style={{ marginTop: "5.6vh" }}>
            <div>
              <div>
                <Text className="qNumber">Question 1</Text>
              </div>
              <Select
                bordered={false}
                className="dropdown"
                placeholder="Choose a Question"
                onChange={onChange1}
              >
                {sqcQuation1.map((q) => {
                  return (
                    <Option key={q.id} value={q.id}>
                      {q.question}
                    </Option>
                  );
                })}
              </Select>
              <div style={{ marginTop: "1.43vh" }}>
                <Input
                  placeholder="Type Your Answer"
                  className="answer"
                  onChange={(e) => setAns1(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginTop: "4.18vh" }}>
              <div>
                <Text className="qNumber">Question 2</Text>
              </div>
              <div>
                <Select
                  bordered={false}
                  className="dropdown"
                  placeholder="Choose a Question"
                  optionFilterProp="children"
                  onChange={onChange2}
                >
                  {sqcQuation2.map((q) => {
                    return (
                      <Option key={q.id} value={q.id}>
                        {q.question}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div style={{ marginTop: "1.43vh" }}>
                <Input
                  className="answer"
                  placeholder="Type Your Answer"
                  onChange={(e) => setAns2(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginTop: "4.18vh" }}>
              <div>
                <Text className="qNumber">Question 3</Text>
              </div>
              <div>
                <Select
                  bordered={false}
                  className="dropdown"
                  placeholder="Choose a Question"
                  optionFilterProp="children"
                  onChange={onChange3}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {sqcQuation3.map((q) => {
                    return (
                      <Option key={q.id} value={q.id}>
                        {q.question}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div style={{ marginTop: "1.43vh" }}>
                <Input
                  className="answer"
                  placeholder="Type Your Answer"
                  onChange={(e) => setAns3(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "6.3vh" }}>
            <Button className="signupBtn" onClick={() => setAnswers()}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
