import React from "react";
import { Typography } from "antd";
import Support from "../../../components/Support/Support";
import Return from "../../../components/Return/Return";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const OtpError = () => {
  const { Text } = Typography;

  return (
    <div className="container">
      <div className="backScreen">
        <Return />
      </div>
      <div className="backContent">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <ExclamationCircleOutlined
              style={{ fontSize: "50px", color: "#F84F44" }}
            />
          </div>
          <div style={{ marginTop: "12px" }}>
            <Text
              style={{
                fontFamily: "SF Pro Text",
                fontStyle: "normal",
                fontSize: "27px",
                fontWeight: "500",
                color: "white",
              }}
            >
              This OTP has expired.
            </Text>
          </div>
          <div style={{ marginTop: "74px" }}>
            <Support />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpError;
