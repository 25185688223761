import React from 'react';
import { Tabs } from 'antd';
import Header from '../../components/Header/Header';
import Assets from "../../pages/Assets/Assets";
import Market from "../../pages/market/Market";
import { useLocation } from "react-router-dom";
const { TabPane } = Tabs;

const Tab = () => {
    const location = useLocation();
    return (
        <div style={{ width: "100%", height: "100vh" }}>
            <div>
                <Header />
            </div>
            <div style={{ background: "#0E1E2E", height: "88.8vh",width:"100%"}}>
                <Tabs defaultActiveKey="1" centered tabBarStyle={{ color: "white", border: "none !important", fontFamily: "DisRegular"}}>
                    <TabPane tab="Your Assets" key="1">
                        <Assets id={location.state.id}/>
                    </TabPane>
                    {location.state.id==="USD"?<></>:
                    <TabPane tab="Market" key="2">
                        <Market id={location.state.id}/>
                    </TabPane>
                    }
                </Tabs>
            </div>
        </div>

    )
}

export default Tab