import { useLocation, Navigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";


export default function ProtectedRoute({ children }) {
  let user = supabase.auth.user();
  let location = useLocation();

  if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
}