
import Header from "../../components/Header/Header";
import { Form, Input, Button, Card, Space, Typography } from "antd";

const EnableTwoFA = () => {
    const { Text } = Typography;

    return (
        <div className="enabletwofa_div">
            <Header />

            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "45%",

                    transform: "translate(-50%, -50%)",
                }}
            >

                <Text
                    style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontStyle: "normal",
                        fontSize: "35px",
                        fontWeight: "500",
                    }}
                >
                    Enable 2FA
                </Text>
                <div
                    style={{ padding: "20px", display: "flex", justifyContent: "center" }}
                >
                    <Text
                        style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontStyle: "normal",
                            fontSize: "15px",
                            fontWeight: "500",
                        }}
                    >
                        Enter OTP from registred  Google Authenticator for Ryki
                    </Text>
                </div>
                <Card
                    className="maxtryerror-heading"
                    headStyle={{
                        border: "#ffffff",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "'Poppins', sans-serif",
                        fontStyle: "normal",
                        fontSize: "28px",
                        fontWeight: "400",
                    }}

                    style={{ backgroundColor: "#E8E8E8", width: 600, borderRadius: "10px" }}
                >
                    <Form
                        style={{ padding: "80px", paddingTop: "0px" }}
                        layout="vertical"
                        name="twofa"
                        className="twofa_form"
                        initialValues={{
                            remember: true,
                        }}
                    //onFinish={onSubmit}
                    >
                        <Form.Item name="twofa_otp"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter OTP",
                                },
                            ]}
                        >
                            <Input
                                style={{ backgroundColor: "#dee1e4", height: "60px" }}
                                placeholder="OTP"
                            //  onChange={(e) => setConfirm(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item style={{ paddingTop: "60px", margin: "0px" }}>
                            <Space>
                                <Button
                                    style={{ width: "450px", height: "60px" }}
                                    type="primary"
                                    htmlType="submit"
                                    className="primary-button"
                                >
                                    Done
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};




export default EnableTwoFA;