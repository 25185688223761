import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./client/pages/signin/Signin";
import OtpSignin from "./client/pages/gogleotpentry/OtpSignin";
import OtpError from "./client/pages/errors/OtpError/OtpError";
import Maxtryerror from "./client/pages/errors/MaxError/Maxtryerror";
import SetPassword from "./client/pages/password/SetPassword";
import Settings from "./client/pages/settings/Settings";
import ChangePassword from "./client/pages/password/ChangePassword";
import Dashboard from "./client/pages/dashboard/Dashboard";
import EnableTwoFA from "./client/pages/settings/EnableTwoFA";
import Signup from "./client/pages/signup/Signup";
import SecQA from "./client/pages/SecQA/SecQA";
import TwoFA from "./client/pages/TwoFA/TwoFA";
import TwoFAIp from "./client/pages/TwoFA/TwoFAIp";
import Tab from "../src/client/pages/market/Tab";
import Faq from "./client/pages/settings/Faq";
import ProtectedRoute from "./client/components/ProtectedRoute";
import NotFound from "./client/pages/errors/NotFound/NotFound";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Signin />} />
        <Route path={"signin"} element={<Signin />} />
        <Route path={"maxtryerror"} element={<Maxtryerror />} />
        <Route path={"otpsignin"} element={<OtpSignin />} />
        <Route path={"otperror"} element={<OtpError />} />
        <Route path={"setpassword"} element={<SetPassword />} />
        <Route path={"signup"} element={<Signup />} />
        <Route path={"secQA"} element={<SecQA />} />
        <Route path={"twoFA"} element={<TwoFA />} />
        <Route path={"twoFAIp"} element={<TwoFAIp />} />
        <Route
          path={"otpsignin"}
          element={
            <ProtectedRoute>
              <OtpSignin />
            </ProtectedRoute>
          }
        />
        <Route
          path={"dashboard"}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={"Tab"}
          element={
            <ProtectedRoute>
              <Tab />
            </ProtectedRoute>
          }
        />
        <Route
          path={"settings"}
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path={"changepassword"}
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path={"enabletwofa"}
          element={
            <ProtectedRoute>
              <EnableTwoFA />
            </ProtectedRoute>
          }
        />
        <Route
          path={"FAQ"}
          element={
            <ProtectedRoute>
              <Faq />
            </ProtectedRoute>
          }
        />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
