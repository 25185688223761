import {Typography} from "antd";
import { useNavigate} from "react-router-dom";
import React from "react";
import {Button } from "antd";
import {supabase} from "../../../supabaseClient";
import logo from "../../../Assets/Logo.svg";
import arrow from "../../../Assets/upload.svg";
import "./Header.css";

function Header() {
  const { Text } = Typography;
  let navigate = useNavigate();
  async function logout() {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      navigate("/signin");
    }
  }
  return (
    <>
      <div
        className="header"
      >
        <div
          className="header-container"
        >
          <div style={{ marginLeft: "7vw" }} onClick={()=>navigate("/dashboard")}>
            <img src={logo} />
          </div>
          <div>
            <Button type="link"  onClick={() => navigate("/settings")}>
            <Text className="header-link" >Settings</Text>
            </Button>
            <Button type="link"  onClick={() => logout()}>
            <Text className="header-link" style={{marginRight:"20px"}}>Log Out</Text>
            <img src={arrow} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
