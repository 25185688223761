import React, { useState, useEffect } from "react";
import {Steps} from "antd";
import logo from "../../../Assets/Logo.svg";
import "./ProgressStep.css";
const { Step } = Steps;

function ProgressStep(props) {
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    setCurrent(props.current);
  }, []);
  return (
    <>
      <div className="progress-container">
        <div style={{ marginTop: "9.37vh" }}>
          <img src={logo} style={{ width: "10.22vw", height: "5vh" }} />
        </div>
        <div style={{marginTop:"10vh"}}>
          <Steps
            direction="vertical"
            current={current}
            labelPlacement="vertical"
            className="stepsContainer"
          >
            <Step
              title="Account authentication"
              className="inactive"
              style={{ fontFamily: current === 0 ? "SemiBold" : "Light" }}
            />
            <Step
              title="Set Password"
              className="inactive"
              style={{ fontFamily: current === 1 ? "SemiBold" : "Light" }}
            />
            <Step
              title="Security Questions"
              className="inactive"
              style={{ fontFamily: current === 0 ? "SemiBold" : "Light" }}
            />
            <Step
              title="2FA Verification"
              className="inactive"
              style={{ fontFamily: current === 0 ? "SemiBold" : "Light" }}
            />
          </Steps>
        </div>
      </div>
    </>
  );
}

export default ProgressStep;
