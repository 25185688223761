import React from "react";
import { Typography } from "antd";
import email from "../../../Assets/email.svg";
import phone from "../../../Assets/phone.svg";
import "./Support.css";

const { Text } = Typography;
const Support = () => {
    return (
        <div className="support-container">
            <div style={{ marginBottom: "2vh" }}>
                <Text className="support-title">Please contact support to retrieve your account.</Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
                    <div>
                        <img src={email} />
                    </div>
                    <div style={{ marginLeft: "1.25vw" }}>
                        <Text className="contact">support@ryki.io</Text>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div>
                        <img src={phone} />
                    </div>
                    <div style={{ marginLeft: "1.25vw" }}>
                        <Text className="contact">999.999.999</Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
