import React from "react";
import { Typography } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./TwoFA.css";

const { Text } = Typography;
const Slider = () => {
  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <div style={{display:"flex"}}>
        <Text className="slide">
          After opening the authenticator app, set-up a new account
        </Text>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div style={{display:"flex"}}>
        <Text className="slide">
          Scan the QR code using your phone OR enter the key to set-up your
          account
        </Text>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div style={{display:"flex"}}>
        <Text className="slide">
          Select the time-based and name the account
        </Text>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default Slider;
