import React, { useEffect, useState, useRef } from "react";
import { Typography, Button, Input, message, Space, Row, Col } from "antd";
import ProgressStep from "../../components/ProgressStep/ProgressStep";
import { CopyOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Slider from "./Slider";
import "./TwoFA.css";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
function TwoFA() {
  const current = 3;
  const [secretkey, setsecretkey] = useState(null);
  const [imgData, setImgData] = useState(null);
  const textAreaRef = useRef(null);
  let navigate = useNavigate();
  useEffect(() => {
    fetch("https://us-central1-ryki-client.cloudfunctions.net/speakeasy-2fa")
      .then((res) => res.json())
      .then((data1) => {
        setImgData(data1.url);
        setsecretkey(data1.secret.base32);
      });
  }, []);
  function copyToClipboard() {
    textAreaRef.current.select();
    navigator.clipboard.writeText(textAreaRef.current.input.value);
    message.success("Copied Successfully");
  }

  return (
    <div className="container">
      <ProgressStep current={current} />
      <div className="twoFaContainer">
        <Row
          style={{
            marginTop: "6%"
          }}
          justify="center"
        >
          <Col>
            <Space
              direction="vertical"
              style={{
                display:"flex",
                flexDirection:"column",
                alignItems:"center"
              }}
            >
              <div>
                <Text className="title">2FA Verification</Text>
              </div>
              <div
                style={{
                  width:"350px",
                  textAlign: "center",
                }}
              >
                <Text className="info">
                  Set-up 2FA with Google Authenticator by following these steps
                </Text>
              </div>
              <div>
                <img src={imgData} />
              </div>
              <div>
                <Text
                  style={{
                    fontSize: "1vw",
                    fontFamily: "medium",
                    color: "white",
                  }}
                >
                  Set-up Key:
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Input
                  ref={textAreaRef}
                  disabled
                  bordered={false}
                  style={{
                    width: "480px",
                    padding:"15px 30px",
                    borderRadius: "15px",
                    color: "white",
                    backgroundColor: "#ffffff0a",
                  }}
                  value={secretkey}
                />
                <div
                  style={{ position: "absolute", right: "4%" }}
                  onClick={() => copyToClipboard()}
                >
                  <CopyOutlined
                    style={{ fontSize: "18px", color: "white" }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "500px"
                }}
              >
                <Slider />
              </div>
              <div>
                <Button
                  className="signupBtn"
                  onClick={() =>
                    navigate("/twoFAIp", { state: { secretkey: secretkey } })
                  }
                >
                  Continue
                </Button>
              </div>
              <div >
                <Link to="/dashboard">
                  <Text className="link">Skip Set-up</Text>
                </Link>
              </div>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TwoFA;
