import React, { useState } from "react";
import { Form, Input, Button,Typography } from "antd";
import { supabase } from "../../../supabaseClient";
import { useNavigate} from "react-router-dom";
import ProgressStep from "../../components/ProgressStep/ProgressStep";
import "./Password.css";

const SetPassword = () => {
  const current = 1;
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  let navigate = useNavigate();
  const { Text } = Typography;

  const onSubmit = async () => {
    const { error } = await supabase.auth.update({ password: password });
    if (!error) {
      navigate("/secQA");
    }
  };

  return (
    <div className="container">
      <div>
        <ProgressStep current={current} />
      </div>
      <div className="set-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "27.8vh",
          }}
        >
          <div style={{ marginBottom: "23px" }}>
            <Text className="entry-text">
              Enter a new password for your account
            </Text>
          </div>
          <div
            style={{
              width: "24.5vw",
              height: "6.72vh",
              marginBottom: "7vh",
              textAlign: "center",
            }}
          >
            <Text className="info-text">
              The password should be alpha-numeric and atleast 8 characters long
            </Text>
          </div>
        </div>
        <div>
          <Form
            layout="vertical"
            name="setpassword"
            className="setPassword-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onSubmit}
          >
            <Form.Item
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  pattern: new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})"),
                  message: "Please enter an valid password!",
                },
              ]}
            >
              <Input.Password
                bordered={false}
                className="input-div"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              style={{ marginTop: "5.5vh" }}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                bordered={false}
                className="input-div"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              style={{
                marginTop: "7vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button className="signupBtn" htmlType="submit">
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
