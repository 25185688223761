import React, { useEffect, useState } from "react";
import { Typography, Spin, Row, Col } from "antd";
import union from "../../../Assets/Union.svg";
import Bitcoin from "../../../Assets/BitcoinL.svg";
import ETHL from "../../../Assets/EthL.svg";
import USDL from "../../../Assets/USDL.svg";
import USDTL from "../../../Assets/USDTL.svg";
import USDI from "../../../Assets/USD.svg";
import BTC from "../../../Assets/Bitcoin.mp4";
import ETH from "../../../Assets/Ethereum.mp4";
import USDC from "../../../Assets/USDC.mp4";
import USDT from "../../../Assets/USDTL.mp4";
import USD from "../../../Assets/USD.mp4";
import { supabase } from "../../../supabaseClient";
import axios from "axios";
import "./Assets.css";
const { Text } = Typography;
var unallocated = 0;
const Assets = (props) => {
  let user = supabase.auth.user();
  const colors = [
    "linear-gradient(206deg, #C24600 42.71%, rgba(182, 161, 136, 0) 89.8%)",
    "linear-gradient(206deg, #0093C2 42.71%, rgba(0, 147, 194, 0) 89.8%)",
    "linear-gradient(206deg, #F6A152 42.71%, rgba(246, 161, 82, 0) 89.8%)",
    "linear-gradient(90deg, #FFFA76 42.71%, rgba(143, 118, 255, 0) 89.8%)",
    "linear-gradient(90deg, #8431D8 42.71%, rgba(223, 82, 246, 0) 89.8%)",
  ];
  const [getFiat, setFiat] = useState("");
  const [userassets, setuserassets] = useState([]);
  const [getEth, setEth] = useState("");
  const [clientpool, setClientPool] = useState([]);
  const [investmentDetails, setInvestmentDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    supabase
      .from("profile")
      .select("*")
      .eq("user_id", user.id)
      .then(({ data }) => {
        if (user) {
          axios
            .post("/api/fireblock", {
              fireBlock_id: data[0].fireBlockID
            })
            .then(async (res) => {
              await supabase
                .from("profile")
                .select("*")
                .eq("user_id", user.id)
                .then(({ data }) => {
                  if (data[0].fiat_amount !== null) {
                    setFiat(data[0].fiat_amount);
                    setEth(data[0].eth_amount);
                  }
                  setuserassets(
                    res.data.filter((f) => f.id === data[0].fireBlockID)[0]
                      .assets
                  );
                  supabase
                    .from("clientpool")
                    .select("*")
                    .match({ currency: props.id, client_id: data[0].id })
                    .then(async ({ data, error }) => {
                      setClientPool(data);
                      await supabase
                        .from("investmentDetails")
                        .select("*")
                        .match({ currency: props.id })
                        .then(({ data, error }) => {
                          setInvestmentDetails(data);
                          setLoading(false);
                        });
                    });
                });
            });
        }
      });
  }, []);
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <Spin size="large" />
    </div>
  ) : (
    <Row style={{marginTop: "1%",width:'100%',marginLeft:"5%"}}  gutter={[{xs: 8,sm: 16,md: 24,lg: 32},16]}>
      <>
        <Col
        >
          <div>
            <div className="currencyAssets">
              <video className="videoTag" autoPlay loop muted>
                <source
                  src={
                    props.id === "BTC"
                      ? BTC
                      : props.id === "ETH"
                      ? ETH
                      : props.id === "USDC"
                      ? USDC
                      : props.id === "USD"
                      ? USD
                      : USDT
                  }
                  type="video/mp4"
                />
              </video>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10vh",
                }}
              >
                <img
                  src={
                    props.id === "BTC"
                      ? Bitcoin
                      : props.id === "ETH"
                      ? ETHL
                      : props.id === "USDC"
                      ? USDL
                      : props.id === "USD"
                      ? USDI
                      : USDTL
                  }
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0%",
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${union})`,
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "50%",
                    borderBottomLeftRadius: "31px",
                    borderBottomRightRadius: "31px",
                    backgroundSize: "contain",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text className="assetId">
                      {props.id === "USDT_ERC20"
                        ? "Tether"
                        : props.id === "ETH"
                        ? "Ethereum"
                        : props.id === "BTC"
                        ? "Bitcoin"
                        : props.id === "USDC"
                        ? "USD Coin"
                        : props.id}
                    </Text>
                    <Text className="asset">
                      {props.id === "USD" ? "$" : ""}
                      {userassets.map((e) => {
                        if (props.id === "USD") {
                          unallocated = getFiat;
                        } else if (props.id === "ETH") {
                          unallocated = getEth;
                        } else if (e.id === props.id) {
                          unallocated = e.total;
                        }
                      })}
                      {(
                        Number(
                          Object.values(clientpool).reduce((t, v, i) => {
                            let singleInvestment = investmentDetails.filter(
                              (e) => e.id === Number(v.investment_id)
                            );
                            var effectiveDate = new Date(
                              singleInvestment[0].effectiveDate
                            );
                            var returnDate = new Date(
                              singleInvestment[0].expiryDate
                            );

                            var date = new Date();
                            var currDate = new Date(
                              date.getMonth() +
                                1 +
                                "/" +
                                date.getDate() +
                                "/" +
                                date.getFullYear()
                            );

                            const diffTime = Math.abs(
                              returnDate - effectiveDate
                            );
                            const diffCurrTime = Math.abs(
                              currDate - effectiveDate
                            );

                            const diffDays =
                              singleInvestment[0].structure === "OT"
                                ? Math.ceil(
                                    diffCurrTime / (1000 * 60 * 60 * 24)
                                  )
                                : Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                            var currDaysDiff = Math.ceil(
                              diffCurrTime / (1000 * 60 * 60 * 24)
                            );

                            if (
                              diffDays > currDaysDiff ||
                              singleInvestment[0].structure === "OT"
                            ) {
                              var currDaysDiff = Math.ceil(
                                diffCurrTime / (1000 * 60 * 60 * 24)
                              );
                            } else {
                              currDaysDiff = diffDays;
                            }
                            return (
                              t +
                              Number(v.quantity) +
                              Number(v.daily_interest) * currDaysDiff
                            );
                          }, 0)
                        ) + Number(unallocated)
                      ).toFixed(props.id === "USD" ? 2 : 4)}


                    </Text>
                    <Text className="total">Total</Text>
                  </div>
                  {userassets.map((e, i) => {
                    if (e.id !== "ETH" && e.id === props.id) {
                      // ETH should not have hold,available,Locked
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            marginTop: "4.3vh",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <Text className="value">
                              {Number(e.available).toFixed(4)}
                            </Text>
                            <Text className="label">Available</Text>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              marginLeft: "5vw",
                            }}
                          >
                            <Text className="value">
                              {Number(e.frozen).toFixed(4)}
                            </Text>
                            <Text className="label">Hold</Text>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              marginLeft: "5vw",
                            }}
                          >
                            <Text className="value">
                              {Object.values(clientpool)
                                .reduce((t, v, i) => {
                                  let singleInvestment =
                                    investmentDetails.filter(
                                      (e) => e.id === Number(v.investment_id)
                                    );
                                  var effectiveDate = new Date(
                                    singleInvestment[0].effectiveDate
                                  );
                                  var returnDate = new Date(
                                    singleInvestment[0].expiryDate
                                  );

                                  var date = new Date();
                                  var currDate = new Date(
                                    date.getMonth() +
                                      1 +
                                      "/" +
                                      date.getDate() +
                                      "/" +
                                      date.getFullYear()
                                  );

                                  const diffTime = Math.abs(
                                    returnDate - effectiveDate
                                  );
                                  const diffCurrTime = Math.abs(
                                    currDate - effectiveDate
                                  );

                                  const diffDays =
                                    singleInvestment[0].structure === "OT"
                                      ? Math.ceil(
                                          diffCurrTime / (1000 * 60 * 60 * 24)
                                        )
                                      : Math.ceil(
                                          diffTime / (1000 * 60 * 60 * 24)
                                        );

                                  var currDaysDiff = Math.ceil(
                                    diffCurrTime / (1000 * 60 * 60 * 24)
                                  );

                                  if (
                                    diffDays > currDaysDiff ||
                                    singleInvestment[0].structure === "OT"
                                  ) {
                                    var currDaysDiff = Math.ceil(
                                      diffCurrTime / (1000 * 60 * 60 * 24)
                                    );
                                  } else {
                                    currDaysDiff = diffDays;
                                  }

                                  return (
                                    t +
                                    Number(v.quantity) +
                                    Number(v.daily_interest) * currDaysDiff
                                  );
                                }, 0)
                                .toFixed(4)}
                            </Text>
                            <Text className="label">Locked</Text>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col
        >
          {props.id === "USD" ? (
            <div className="unallocated">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Text className="invalue">${Number(getFiat).toFixed(2)}</Text>
                  <Text className="invalue" style={{ marginLeft: "0.4vw" }}>
                    {props.id}
                  </Text>
                </div>
                <div style={{ marginTop: "1vh" }}>
                  <Text className="fixed">Unallocated</Text>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {userassets.map((e, i) => {
            if (e.id === props.id) {
              return (
                <div className="unallocated">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Text className="invalue">
                        {e.id === "ETH"
                          ? Number(getEth).toFixed(4)
                          : Number(e.total).toFixed(4)}
                      </Text>
                      <Text className="invalue" style={{ marginLeft: "0.4vw" }}>
                        {props.id === "USDT_ERC20" ? "USDT" : props.id}
                      </Text>
                    </div>
                    <div style={{ marginTop: "1vh" }}>
                      <Text className="fixed">Unallocated</Text>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {clientpool.map((ele, i) => {
            let singleInvestment = investmentDetails.filter((e) => {
              return e.id ===  Number(ele.investment_id);
            });
            var effectiveDate = new Date(singleInvestment[0].effectiveDate);
            var returnDate = new Date(singleInvestment[0].expiryDate);
            var date = new Date();
            var currDate = new Date(
              date.getMonth() +
                1 +
                "/" +
                date.getDate() +
                "/" +
                date.getFullYear()
            );
            const diffTime = Math.abs(returnDate - effectiveDate);
            const diffCurrTime = Math.abs(currDate - effectiveDate);

            const diffDays =
              singleInvestment[0].structure === "OT"
                ? Math.ceil(diffCurrTime / (1000 * 60 * 60 * 24))
                : Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            var currDaysDiff = Math.ceil(diffCurrTime / (1000 * 60 * 60 * 24));

            if (
              diffDays > currDaysDiff ||
              singleInvestment[0].structure === "OT"
            ) {
              var currDaysDiff = Math.ceil(
                diffCurrTime / (1000 * 60 * 60 * 24)
              );
            } else {
              currDaysDiff = diffDays;
            }
            if (i < 2)
              return (
                <div
                  key={i}
                  className="card"
                  style={{
                    borderImage: colors[i],
                    borderImageSlice: "100",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text className="invalue">
                        {(
                          Number(ele.daily_interest) * currDaysDiff +
                          Number(ele.quantity)
                        ).toFixed(4)}{" "}
                        {props.id === "USDT_ERC20" ? "USDT" : props.id}
                      </Text>
                      <Text className="structure">
                        {singleInvestment[0].structure}
                      </Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2vh",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Principle</Text>
                          <Text className="assetsValue">
                            {Number(ele.quantity).toFixed(4)}{" "}
                            {props.id === "USDT_ERC20" ? "USDT" : props.id}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Interest Accrued</Text>
                          <Text className="assetsValue">
                            {(
                              Number(ele.daily_interest).toFixed(4) *
                              currDaysDiff
                            ).toFixed(4)}{" "}
                            {props.id === "USDT_ERC20" ? "USDT" : props.id}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Rate</Text>
                          <Text className="assetsValue">
                            {singleInvestment[0].rate}%
                          </Text>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1vh",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Effective Date</Text>
                          <Text className="assetsValue">
                            {singleInvestment[0].effectiveDate}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Expiry Date</Text>
                          <Text className="assetsValue">
                            {singleInvestment[0].expiryDate}
                          </Text>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
        </Col>
        <Col
        >
          {clientpool.map((ele, i) => {
            let singleInvestment = investmentDetails.filter(async (e) => {
              return e.id === (await Number(ele.investment_id));
            });
            var effectiveDate = new Date(singleInvestment[0].effectiveDate);
            var returnDate = new Date(singleInvestment[0].expiryDate);
            var date = new Date();
            var currDate = new Date(
              date.getMonth() +
                1 +
                "/" +
                date.getDate() +
                "/" +
                date.getFullYear()
            );
            const diffTime = Math.abs(returnDate - effectiveDate);
            const diffCurrTime = Math.abs(currDate - effectiveDate);

            const diffDays =
              singleInvestment[0].structure === "OT"
                ? Math.ceil(diffCurrTime / (1000 * 60 * 60 * 24))
                : Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            var currDaysDiff = Math.ceil(diffCurrTime / (1000 * 60 * 60 * 24));

            if (
              diffDays > currDaysDiff ||
              singleInvestment[0].structure === "OT"
            ) {
              var currDaysDiff = Math.ceil(
                diffCurrTime / (1000 * 60 * 60 * 24)
              );
            } else {
              currDaysDiff = diffDays;
            }
            if (i > 1)
              return (
                <div
                  key={i}
                  className="card"
                  style={{
                    borderImage: colors[i],
                    borderImageSlice: "100",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text className="invalue">
                        {(
                          Number(ele.daily_interest) * currDaysDiff +
                          Number(ele.quantity)
                        ).toFixed(4)}{" "}
                        {props.id === "USDT_ERC20" ? "USDT" : props.id}
                      </Text>
                      <Text className="structure">
                        {singleInvestment[0].structure}
                      </Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2vh",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Principle</Text>
                          <Text className="assetsValue">
                            {Number(ele.quantity).toFixed(4)}{" "}
                            {props.id === "USDT_ERC20" ? "USDT" : props.id}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Interest Accrued</Text>
                          <Text className="assetsValue">
                            {(
                              Number(ele.daily_interest).toFixed(4) *
                              currDaysDiff
                            ).toFixed(4)}{" "}
                            {props.id === "USDT_ERC20" ? "USDT" : props.id}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Rate</Text>
                          <Text className="assetsValue">
                            {singleInvestment[0].rate}%
                          </Text>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1vh",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Effective Date</Text>
                          <Text className="assetsValue">
                            {singleInvestment[0].effectiveDate}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text className="assetsField">Expiry Date</Text>
                          <Text className="assetsValue">
                            {singleInvestment[0].expiryDate}
                          </Text>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
        </Col>
      </>
    </Row>
  );
};
export default Assets;
